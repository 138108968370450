<template>
	<div v-if="status" id="status-container" class="card card-custom card-stretch gutter-b">
		<div class="status-bar">
			<div
				class="status mr-5 d-flex align-items-center justify-content-center"
				:style="{
					background: `radial-gradient(ellipse at 20px 10px, ${colors[status.status][0]}, ${
						colors[status.status][1]
					})`,
				}"
				@mouseover="showStatus = false"
				@mouseleave="showStatus = true"
			>
				<transition name="fade" mode="out-in">
					<h3 v-if="showStatus" class="my-0">{{ status.status }}</h3>
					<div v-else class="status-info d-flex align-items-center justify-content-start">
						<div class="mr-2">
							<h5 style="white-space: nowrap">
								{{ status.status_change }}
							</h5>
						</div>
						<span>
							<h5 style="font-weight: 300">
								{{ status.status_data }}
							</h5>
						</span>
					</div>
				</transition>
			</div>
		</div>
		<div class="d-flex align-items-center justify-content-between" style="height: 100%">
			<div class="d-flex align-items-center">
				<div style="width: 205px"></div>
				<div>
					<h4>{{ name }}</h4>
					<div v-if="network">
						<span>Network: {{ network }} -</span>
						<span style="font-weight: 500">{{ status.modem }}</span>
					</div>
				</div>
			</div>
			<div class="text-center">
				<b-button
					variant="outline-danger"
					v-if="['admin', 'noc', 'staff'].includes(currentUser.role) || currentUser.showrealtime"
					:to="{ name: 'realtime', params: { id: deviceId } }"
					class="mr-2"
					target="_blank"
					>Realtime</b-button
				>
				<b-button
					variant="outline-secondary"
					v-if="['admin', 'noc', 'staff'].includes(currentUser.role)"
					:href="'https://portal.itcglobal.com/noc/app/remote/' + status.Source"
					class="mr-2"
					target="_blank"
					>NOC</b-button
				>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'IdirectModemStatus',
	props: {
		name: {
			type: String,
			required: true,
		},
		deviceId: {
			type: Number,
			required: true,
		},
		network: {
			type: String,
			required: false,
		},
		promise: {
			required: true,
		},
	},
	data() {
		return {
			loaded: false,
			showStatus: true,
			status: null,
			colors: {
				OK: ['#c4fce5', '#37c583'],
				ALARM: ['#f7ccd0', '#ED6B75'],
				DEACTIVATED: ['#cce0fa', '#659be0'],
				ELSEWHERE: ['#cce0fa', '#659be0'],
				default: ['#cce0fa', '#659be0'],
			},
		};
	},
	computed: {
		...mapGetters(['currentUser']),
	},
	methods: {
		statusHovered() {
			this.showStatus = false;
		},
		load() {
			this.promise.then((resp) => {
				this.status = resp.data.data[this.deviceId];
			});
		},
	},
	created() {
		this.load();
	},
};
</script>

<style scoped>
#status-container {
	height: 75px;
	background: white;
	/* background: linear-gradient(220deg, #91f7f2, #81e9f3); */
}
.status-bar {
	position: absolute;
	height: 100%;
}
.status {
	height: 100%;
	width: 175px;
	min-width: 175px;
	padding: 15px;
	border-radius: 15px 105px 105px 15px;
	transition: all 300ms ease-in-out;
	overflow: hidden;
	/* background: radial-gradient(ellipse at 20px 10px, #f7ccd0, #659be0); */
}
.status:hover {
	min-width: 700px;
	width: 45%;
}
.fade-enter-active {
	transition: opacity 300ms ease-in-out 200ms;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
.noc-link {
	background: radial-gradient(ellipse at 15px 15px, #daf8f7, #6ff3ec);
	padding: 15px 10px;
	border-radius: 5px;
}
@media (max-width: 1700px) {
	.status:hover {
		width: 80%;
	}
}
</style>
